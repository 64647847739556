import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation } from "swiper/modules"
import Spacer from "../components/spacer"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "swiper/css/navigation"

const Team = ({team, caption}) => (
  <div id='team' className='team p40 pr0 bg-slate'>
    <Spacer />
    <div className='flex m-wrap'>
      <div className='w-30 m-100 white'>
        <p className='m0 h1 fade--in' data-sal>The <br className='m-hide'/>Team</p>
        <Spacer className='m-hide' />
        <div className='m-hide controls gap-20 flex fade--in' data-sal>
          <div className='team-prev white'/>
          <div className='team-next white'/>
        </div>
      </div>
      <div className='w-70 m-100 m-mt40'>  
        <Swiper breakpoints={{ 0: { slidesPerView: 1.1, spaceBetween: 20, slidesOffsetAfter: 20 }, 800: { slidesPerView: 2.5, spaceBetween: 20, slidesOffsetAfter: 40, } }} modules={[Navigation]} navigation={{ nextEl: `.team-next`, prevEl: `.team-prev` }}>
          {team.map((member, index) => {
            return (
              <SwiperSlide key={member.node.id}>
                <Member member={member.node} index={index} />
              </SwiperSlide>
            )
          })} 
        </Swiper>
        <div className='m-flex controls gap-20 flex fade--in m-mt40' data-sal>
          <div className='team-prev white'/>
          <div className='team-next white'/>
        </div>
      </div>
    </div>
    <Spacer />
  </div>
)

export default Team

const Member = ({member}) => {
  return (
    <div className='team-member white'>
      <div className='bg-light ratio-2-1 br5 pos-rel overflow'>
        <GatsbyImage className='bg-image' image={member.image.gatsbyImageData} alt={member.name}  />
      </div>
      <p className='mt20 m0 h4'>{member.name}</p>
      <p className='grey m0 mt5'>{member.jobPosition}</p>
    </div>
  )
} 