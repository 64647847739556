import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Testimonial = ({title, content, build, client, image}) => (
  <div id='testimonial' className='testimonial p40'> 
    <div className='flex max-1200 ma m-wrap'>
      <div className='w-50 pr40 m-p0 m-100'>
        <div className='bg-grey ratio-2-1 overflow pos-rel br5 w-100'>
          <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={title} />
        </div>
      </div>
      <div className='w-50 pl40 m-100 m-p0 m-mt40'>
        <p className='h5 m0 mb40 fade--in' data-sal>{title}</p>
        <div className='medium white-space m-reg lh2 grey fade--in' data-sal>{content}</div>
        <div className='mt40 medium m-reg pt40 m-p0 fade--in' data-sal>
          <p className='m0 bold'>{client}</p>
          <p className='m0 grey mt10'>{build}</p>
        </div>
      </div>
    </div>
  </div>
)

export default Testimonial
