import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import scrollTo from "./scrollTo"

const HeroBanner = ({ title, image }) => {
  return (
    <div className='hero-banner h-100vh bg-slate pos-rel flex flex-wrap'>
      <div className='w-100 mta  pt40 z-2 pos-rel text-center flex'>
        <div className='p20 hero--title white mt40 h1 ma'>
          {title.map((text, index) => {
            return (
              <h1 className='m0'><span>{text.title}</span></h1>
            )
          })} 
        </div>
      </div>
      <div className='mta mb20 w-100 z-2 pos-rel white text-center'>
        <p role='presentation' onClick={() => scrollTo('#who')} className='medium m-hide pointer'>Scroll Down</p>
        <div role='presentation' onClick={() => scrollTo('#who')}  className='scroll-icon pointer'><span/></div>
      </div>
      <GatsbyImage className='bg-image br-08' image={image?.gatsbyImageData} alt={title} />
    </div>
  )
}

export default HeroBanner
