import * as React from "react"
import Spacer from "../components/spacer"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation } from "swiper/modules"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "swiper/css/navigation"

const Projects = ({projects, title, subtitle}) => (
  <div id='projects' className='projects flex m-wrap p40 pr0'>
    <div className='w-40 m-100'>
      <p className='h3 m0 fade--in' data-sal>{title}</p>
      <p className='m0 mt20 medium grey max-350 fade--in' data-sal>{subtitle}</p>
      <Spacer className='m-hide' />
      <div className='controls gap-20 flex fade--in m-hide' data-sal>
        <div className='slides-prev'/>
        <div className='slides-next'/>
      </div>
    </div>
    <div className='w-60 m-100 m-mt40'>
      <Swiper breakpoints={{ 0: { slidesPerView: 1.1, spaceBetween: 20, slidesOffsetAfter: 20 }, 800: { slidesPerView: 1.5, spaceBetween: 40, slidesOffsetAfter: 40, } }} modules={[Navigation]} navigation={{ nextEl: `.slides-next`, prevEl: `.slides-prev` }}>
        {projects.map((project, index) => {
          return (
            <SwiperSlide key={project.node.id}>
              <Slide project={project.node} index={index} />
            </SwiperSlide>
          )
        })} 
      </Swiper>
      <div className='controls gap-20 flex fade--in m-mt40 m-flex' data-sal>
        <div className='slides-prev'/>
        <div className='slides-next'/>
      </div>
    </div>
  </div>
)

export default Projects

const Slide = ({project, index}) => {
  return (
    <div className='single-project'>
      <div className='h-70vh bg-grey pos-rel overflow br5'>
        <GatsbyImage className='bg-image' image={project.heroImage.gatsbyImageData} alt={project.name}  />
      </div>
      <p className='mt20 m0 h4'>{project.name}</p>
      <p className='grey m0 mt10'>{project.subtitle}</p>
    </div>
  )
}