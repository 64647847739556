import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation, Autoplay } from "swiper/modules"
import Spacer from "../components/spacer"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "swiper/css/navigation"


const Gallery = ({images}) => (
  <div className='image--gallery p40 pr0'>
    <Swiper autoplay={true} breakpoints={{ 0: { slidesPerView: 1.3, spaceBetween: 20, slidesOffsetAfter: 20 }, 800: { slidesPerView: 3.5, spaceBetween: 20, slidesOffsetAfter: 40, } }} modules={[Navigation, Autoplay]} navigation={{ nextEl: `.image-next`, prevEl: `.image-prev` }}>
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <div className='ratio-3-2 bg-grey pos-rel'>
              <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt='Gallery'  />
            </div>
          </SwiperSlide>
        )
      })} 
    </Swiper>
  </div> 
)

export default Gallery
