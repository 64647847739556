import * as React from "react"

const Introduction = ({title, subtitle, content}) => (
  <div id='who' className='p40 introduction flex m-wrap'>
    <div className='w-50 m-100'>
      <p className='m0 h2 max-400 fade--in' data-sal>{title}</p>
      <p className='m0 grey medium mt20 max-400 fade--in' data-sal>{title}</p>
    </div>
    <div className='w-50 m-100 m-mt40'>
      <div className='medium m-reg white-space grey lh2 fade--in' data-sal>{content}</div>
    </div>
  </div>
)

export default Introduction
