import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.getElementById("popup").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("popup")
    let formData = new FormData(myForm)
    if(formData.get('Name') && formData.get('Email') && formData.get('Number')){
      console.log('test fetch')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.getElementById("popup")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you-popup")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.getElementById("popup").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {
    const formSubmitted = this.state.formSubmitted
    
    if(!formSubmitted){
    return (
      <div className="mt20 m-m0">
        
        <form method="POST" data-netlify="true" name="consultation-form" id="popup" className="popup--form">
            <input type="hidden" name="form-name" value="consultation-form" />
            <div className='flex flex-wrap'>
              <input required={true} name="Name" type="text" className="p20 w-100 pl0 bb1 mr40 m-m0 input" placeholder="Full Name*"/>
              <input required={true} name="Email" type="text" className="p20 w-100 pl0 bb1 mr40 m-m0 input" placeholder="Email*" />
              <input required={true} name="Number" type="text" className="p20 w-100 pl0 bb1 mr40 m-m0 input" placeholder="Phone Number*" />
            </div> 
            <div className="submit mt20 mt40 m-a">
              <button type="submit" className="button block input">Submit</button>
            </div>
          </form>
      </div>
    )}else{
      return <div className="form-section grey mt40 medium m-m0 thank-you" id="thank-you-popup">Thank You for joining the waitlist!<br/> Our team be in touch soon to arrange your consult.</div>
    }
  }
}

export default EnquiryForm
