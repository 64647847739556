import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation } from "swiper/modules"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "swiper/css/navigation"

const Projects = ({projects, title, subtitle}) => (
  <div id='past' className='projects p40 pr0'>
    <div className='flex align-center mb40 fade--in' data-sal>
      <div className='title-area'>
        <p className='h3 m0'>{title}</p>
      </div>
      <div className='controls gap-20 flex mla p40'>
        <div className='slides-prev'/>
        <div className='slides-next'/>
      </div>
    </div>
    <div className='carousel'>
      <Swiper breakpoints={{ 0: { slidesPerView: 1.2, spaceBetween: 20, slidesOffsetAfter: 20 }, 800: { slidesPerView: 2.5, spaceBetween: 40, slidesOffsetAfter: 40 } }} modules={[Navigation]} navigation={{ nextEl: `.slides-next`, prevEl: `.slides-prev` }}>
        {projects.map((project, index) => {
          return (
            <SwiperSlide key={project.id}>
              <Slide project={project} index={index} />
            </SwiperSlide>
          )
        })} 
      </Swiper>
    </div>
  </div>
)

export default Projects

const Slide = ({project, index}) => {
  return (
    <a className='link' target='_blank' rel='noreferrer' href={project.link}>
    <div className='single-project'>
      <div className='ratio-1-2 bg-grey pos-rel overflow br5'>
        <GatsbyImage className='bg-image' image={project.image.gatsbyImageData} alt={project.name}  />
      </div>
      <p className='mt20 m0 h4'>{project.title}</p>
      <p className='grey m0 mt5'>{project.subtitle}</p>
    </div>
    </a>
  )
}