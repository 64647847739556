import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const Cta = ({title, subtitle, gallery}) => (
  <div className='pos-rel overflow p40'>
    <Spacer />
    <Spacer />
    <div className='text-center'>
      <p className='h0 grey white-space m0 fade--in' data-sal dangerouslySetInnerHTML={{ __html: title }} />
      <p className='max-750 lh2 ma medium grey mt40 fade--in' data-sal>{subtitle}</p>
    </div>
    <Spacer />
    <Spacer />
    <div className='cta--gallery'>
      {gallery.map((image, index) => {
        return (  
          <GatsbyImage className='image' image={image.gatsbyImageData} alt='CTA Image'  />
        )
      })} 
    </div>
  </div>
)

export default Cta
